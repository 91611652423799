<template>
  <div id="openDialog">
    <el-dialog
      title="开闸"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form :model="form" :rules="formRules" ref="form" label-width="100px">
        <el-form-item label="放行类型" prop="releaseType">
          <el-radio-group v-model="form.releaseType">
            <el-radio label="异常放行"></el-radio>
            <el-radio label="收费放行"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="异常类型"
          prop="releaseReason"
          v-if="form.releaseType === '异常放行'"
        >
          <el-select
            v-model="form.releaseReason"
            placeholder="请选择异常类型"
            clearable
          >
            <el-option
              v-for="item of select_releaseReason"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="原因"
          prop="remark"
          v-if="form.releaseReason === '其他原因'"
        >
          <el-input
            v-model="form.remark"
            type="textarea"
            :autosize="{ minRows: 2 }"
            placeholder="请输入原因"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      channelId: "",
      form: {
        releaseType: "收费放行",
        releaseReason: "",
        remark: "",
      },
      formRules: {
        releaseType: [
          { required: true, message: "请选择出入口类型", trigger: "change" },
        ],
        releaseReason: [
          { required: true, message: "请选择异常类型", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.$set(this, "form", {
        releaseType: "收费放行",
        releaseReason: "",
        remark: "",
      });
      this.$refs["form"].resetFields();
    },
    async confirmBtn() {
      let flag = true;
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }
      try {
        this.loading = true;
        let res = await this.$http.post("/channel/manual/opening", {
          ...this.form,
          channelId: this.channelId,
          releaseReason:
            this.form.releaseReason && this.form.releaseType === "异常放行"
              ? this.form.releaseReason
              : null,
        });
        if (res.code === 0) {
          this.$message.success("操作成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#openDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
            margin-right: 50px;
          }
        }
      }
    }
  }
}
</style>
